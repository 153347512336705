import React from "react"
import { interpolateString } from "../../helpers/Strings"
import { Subjects } from "../../data/subjects"
import { Routes } from "../../data/routes"
import CTA from "../../components/CTA"
import Card from "../../components/card"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { CheckIcon } from "@heroicons/react/outline"

const Content = {
  pageName: "Subjects We Teach",
  subheader: "Subjects We Teach",
  description:
    "Yup tutors are experts in all K-12 math, from addition to calculus AB",
  header:
    "Yup tutors are experts in all K-12 math, from addition to calculus AB",

  // Subjects section
  subjects: Subjects,
  viewSessionsHeader: "View {{subject}} Yup Sessions",
  viewSessionAction: "View",
  lookingForMoreHeader: "Looking for tutoring in another math subject?",
  lookingForMoreText: `We know that some schools have different naming conventions for math
    classes. We cover Integrated Math (Math 1, Math 2, Math 3) and all K-12
    grade Common Core math. Yup can also be used for SAT and SAT II
    preparation. To confirm whether we cover your class or subject area,
    email support@yup.com.`,

  // CTA section
  cta: "Curious about how we teach these topics?",
  ctaActions: [
    {
      link: Routes.learningFundamentals,
      text: "How we teach",
    },
  ],
}

type SubjectTopic = {
  name: string
  description: string
}

type SubjectSession = {
  source: string
  target: string
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-secondary tracking-wide uppercase">
            {Content.subheader}
          </h2>
          <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {Content.header}
          </h1>
        </div>
      </div>
    </div>
  )
}

function SubjectsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/lists/grid-lists#component-a26a744b444974a4cc73cb5886b8da6a
  return (
    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px max-w-3xl mx-auto">
      {Content.subjects.map((subject, actionIdx) => (
        <div
          key={subject.subject}
          className={`
            ${
              actionIdx === 0
                ? "w-full rounded-tl-lg rounded-tr-lg sm:rounded-tr-none "
                : ""
            }
            ${actionIdx === 1 ? "sm:rounded-tr-lg " : ""}
            ${
              actionIdx === Content.subjects.length - 2
                ? "sm:rounded-bl-lg "
                : ""
            }
            ${
              actionIdx === Content.subjects.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none "
                : ""
            }
            relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-secondary-500
          `}
        >
          <div>
            <Img src={subject.icon} alt={subject.subject} />
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <a href={`#${subject.id}`} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {subject.subject}
              </a>
            </h3>
          </div>
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  )
}

function OtherSubjectsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/headings/section-headings#component-e41884f80859d3afa20019532c28c1fb
  return (
    <div className="p-5 my-4 max-w-3xl mx-auto">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {Content.lookingForMoreHeader}
      </h3>
      <p className="mt-2 max-w-4xl text-sm text-gray-500">
        {Content.lookingForMoreText}
      </p>
    </div>
  )
}

function SubjectTopics(props: { topics: Array<SubjectTopic> }) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-482ea288d556374012ca6eed5391c5f0
  return (
    <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
      {props.topics.map((topic: any) => (
        <div key={topic.name} className="relative">
          <dt>
            <CheckIcon
              className="absolute h-6 w-6 text-green-500"
              aria-hidden="true"
            />
            <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
              {topic.name}
            </p>
          </dt>
          <dd className="mt-2 ml-9 text-base text-gray-500">
            {topic.description}
          </dd>
        </div>
      ))}
    </dl>
  )
}

function SubjectSessions(props: { sessions: Array<SubjectSession> }) {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/lists/grid-lists#component-d6b6c178a9f460d37c542870139e940e
  return (
    <ul
      role="list"
      className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
    >
      {props.sessions.map(session => (
        <li key={session.target} className="relative">
          <a href={session.target} target="_blank">
            <div className="group block min-w-72 w-full h-24 md:h-56 aspect-w-10 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <Img
                src={session.source}
                alt={session.target}
                className="pointer-events-none group-hover:opacity-75"
                cover={true}
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">{Content.viewSessionAction}</span>
              </button>
            </div>
            <p className="mt-2 block text-sm text-secondary uppercase tracking-widest font-bold text-center">
              {Content.viewSessionAction} →
            </p>
          </a>
        </li>
      ))}
    </ul>
  )
}

function SubjectTopicsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/application-ui/headings/section-headings#component-c514bd50feabd9311204463df38ea764

  return (
    <div>
      {Content.subjects.map(subject => (
        <div
          key={`subject-${subject.subject}`}
          id={subject.id}
          className="pt-8"
        >
          <Card>
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">
                {subject.subject}
              </h2>
            </div>
            <SubjectTopics topics={subject.topics} />
            <div className="mt-12 mb-4 pb-5 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {interpolateString(Content.viewSessionsHeader, {
                  subject: subject.subject,
                })}
              </h3>
            </div>
            <SubjectSessions sessions={subject.sessions} />
          </Card>
        </div>
      ))}
    </div>
  )
}

const MathTutoring = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.mathTutoring}
      />
      <Header />
      <SubjectsSection />
      <OtherSubjectsSection />
      <SubjectTopicsSection />
      <CTA title={Content.cta} actions={Content.ctaActions} />
    </Layout>
  )
}

export default MathTutoring
